import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Note from "./Note";
import CreateArea from "./CreateArea";
import { db,signInwithGoogle,signOutFromGoogle, onAuth } from "../firebase";
import { collection, getDocs, addDoc, deleteDoc, updateDoc, doc, query, orderBy, where } from "firebase/firestore"


function App() {
  const [currentUser, setCurrentUser] = useState(localStorage.getItem('user'));
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [signInOut, setSignInOut] = useState("Sign In")
  function handleLoginOut() {
    if (isLoggedIn) {
      signOutFromGoogle()
      console.log("Add logout function");
      setCurrentUser(null);
      setIsLoggedIn(false);
      setSignInOut("Sign In");
      localStorage.removeItem("user")
      getData();
    } else {
      loginWithGoogle()
    }
  }
  const loginWithGoogle = async () => {
    const user=localStorage.getItem("user");
    if(user){
      setCurrentUser(user);
      setIsLoggedIn(true);
      setSignInOut("Sign Out");
      return;
    }
    const result = await signInwithGoogle();
    const newUser = result.user.uid
    setCurrentUser(newUser);
    setIsLoggedIn(true);
    setSignInOut("Sign Out")
    localStorage.setItem('user', newUser);
  }


  useEffect(() => {
    console.log(currentUser);
    if (currentUser) {
      getData()
      setIsLoggedIn(true)
      setSignInOut("Sign Out")
    } else {
      console.log("Please login to use this app")
      setIsLoggedIn(false)
      setSignInOut("Sign In")
    }
  }, [currentUser, isLoggedIn]);


  //Setup "notes" State
  const [notes, setNotes] = useState([]);

  //CRUD of the notes
  ////READ
  //Load data from database
  const notesCollectionRef = collection(db, "notes");

  const getData = async () => {
    //In order to make the latest note shows the first, need to sort with time and descent. 
    const data = await getDocs(query(
      notesCollectionRef,
      where("user", "==", currentUser),
      orderBy('time', "desc")))
    //Map thru the docs get from database and put it into an array
    const arrayData = data.docs.map(doc => ({ id: doc.id, ...doc.data(), editable: "false" }))
    //Set the array to local var. 

    setNotes(arrayData)
  }

  useEffect(() => {
    getData()
    if (!currentUser) {
      console.log("Please login to use this app")
    } 
  }, [isLoggedIn,currentUser])

  ////ADD
  const addNote = async (newNote) => {
    const saveTime = new Date()
    await addDoc(notesCollectionRef, {
      user: currentUser,
      title: newNote.title,
      content: newNote.content,
      time: saveTime
    });
    getData()
  }


  ////EDIT
  const updateNote = async (updateNote) => {
    //passing updateNote from the note.jsx
    //updateTarget is set for firebase function "updateDoc" to recognize which document to update. 
    //It takes 3 parameter: the database, the collection and the document ID. 
    const updateTarget = doc(db, "notes", updateNote.id)
    const saveTime = new Date()
    await updateDoc(updateTarget, {
      user: currentUser,
      title: updateNote.title,
      content: updateNote.content,
      time: saveTime
    })
    getData()

  }

  ////DELETE

  const deleteNote = async (id) => {
    const toDelete = doc(db, "notes", id)
    await deleteDoc(toDelete)
    getData()
  }


  //Rendering the result
  return (
    <div>
      <Header
        onClick={handleLoginOut}
        headerButton={signInOut}
      />
      <CreateArea
        onAdd={addNote}
        isLoggedIn={currentUser} />
      {notes.map((noteItem) => {
        return (
          <Note
            key={noteItem.id}
            id={noteItem.id}
            {...noteItem}
            onDelete={deleteNote}
            onUpdate={updateNote}
            //the updateNote function will take place either user leave the editing, which is unBlur, or clikced save. 
            onBlur={updateNote}
          />
        );
      })}
      <Footer />
    </div>
  );
}

export default App;