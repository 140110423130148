import React from "react";


function Header(props) {
  function handleClick(){
    props.onClick();
  };

  return (
    <header>
      <div className="row">
        <div className="col-9">
          <h1>Keeper</h1>
        </div>
        <div className="col-3">
          <button className="btn btn-outline-light" onClick={handleClick}>{props.headerButton}</button>
        </div>
      </div>

    </header>
  );
}

export default Header;
