import React, { useState } from "react";


function CreateArea(props) {
  const [note, setNote] = useState({
    title: "",
    content: ""
  });

  function handleChange(event) {
    const { name, value } = event.target;
    console.log(name);
    console.log(value);

    setNote(prevNote => {
      return {
        ...prevNote,
        [name]: value
      };
    });
    console.log(note);
  }

  function submitNote(event) {
    props.onAdd(note);
    setNote({
      title: "",
      content: ""
    });
    event.preventDefault();
  }
  if(props.isLoggedIn){
    return (
      <div>
        <form>
          <input
            name="title"
            onChange={handleChange}
            value={note.title}
            placeholder="Click to start editing"
          />
          <textarea          
            name="content"
            onChange={handleChange}
            value={note.content}
            placeholder="Take a note..."
            rows="3"
          />
          <button onClick={submitNote}>Add</button>
        </form>
      </div>
    );
  }else{
    return (
      <div className="pleaseSignIn">
        <h1>Please Sign In to use Keeper</h1>
      </div>
    )
  }
  
}

export default CreateArea;
